import { pathOr } from "ramda";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CarDetailsCard from "../../components/car-details-card";
import WithAuth from "../../layouts/with-auth";
import { WithUserMenu } from "../../layouts/with-menu";
import { getCarFavourites } from "../../services";

const Favourites = ({ user }) => {
  const [favoutiresList, setFavouritesList] = useState([]);
  const getFavouritesList = () => {
    getCarFavourites("pageNo=1").then((res) => {
      setFavouritesList(res?.data?.[0]?.data);
    });
  };
  useEffect(() => {
    getFavouritesList();
  }, []);
  return (
    <div className="flex flex-1 flex-col md:flex-[0.8] p-5 pb-16 bg-[#fafafa]">
      <p className="text-[#053361] text-2xl font-bold text-left">Favorites</p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-5">
        {favoutiresList?.map((data) => {
          return (
            <CarDetailsCard
              data={data}
              key={data._id}
              getCarList={() => {
                getFavouritesList();
              }}
              isLoggedIn={user?.isLoggedIn}
              isOnFavouritePage={true}
            />
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: pathOr({}, ["user"], state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithAuth(WithUserMenu(Favourites)));
