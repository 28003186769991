import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import { WithLogoHeaderFooter } from "../../layouts/with-logo-header-footer";
import { changePassword } from "../../services";
import { useNavigate } from "react-router";

const UserResetPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");

  return (
    <div className="flex flex-col md:w-[35%] mx-auto px-5 md:px-0 pt-40 md:pt-20">
      <p className="text-4xl text-[#053361] font-bold">Reset your password</p>
      <p className="text-base font-normal text-[#565D65] mt-2">
        Almost done! Now, change your password to something you haven't used
        before.
      </p>
      <div className="w-full mt-5">
        <Formik
          initialValues={{ password: "", confirmPassword: "" }}
          enableReinitialize={true}
          validate={async (values) => {
            const errors = {};
            if (!values?.password) {
              errors.password = "Please enter password";
            } else if (
              !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{6,}$/i.test(
                values.password
              )
            ) {
              errors.password = "Please enter valid password format";
            }
            if (values?.password !== values?.confirmPassword) {
              errors.confirmPassword = "Your passwords doesn't matches";
            }
            return errors;
          }}
          onSubmit={async (values) => {
            changePassword({ password: values?.password }).then((res) => {
              if (res?.status === 201) {
                navigate("/signin", {
                  state: {
                    message: "Password changes Successfully! Please log in.",
                  },
                });
              } else {
                setError(res?.message);
              }
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="flex flex-col">
              <p className="text-left text-base mt-5">Password</p>
              <TextField
                name="password"
                onChange={handleChange}
                value={values?.password}
                onBlur={handleBlur}
                className="my-2"
                type="password"
              />
              {errors?.password && touched?.password && (
                <p className="text-left text-xs text-red-700">
                  {errors?.password}
                </p>
              )}
              <p className="text-left text-base mt-5">Confirm Password</p>
              <TextField
                name="confirmPassword"
                onChange={handleChange}
                value={values?.confirmPassword}
                onBlur={handleBlur}
                className="my-2"
                type="password"
              />
              {errors?.confirmPassword && touched?.confirmPassword && (
                <p className="text-left text-xs text-red-700">
                  {errors?.confirmPassword}
                </p>
              )}
              <div className="mt-5 w-full">
                <Button
                  variant="outlined"
                  type="submit"
                  className="w-full"
                  disabled={isSubmitting}
                >
                  CONFIRM NEW PASSWORD
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {error && <p className="text-sm text-red-700 mt-2">{error}</p>}
    </div>
  );
};

export default WithLogoHeaderFooter(UserResetPassword);
