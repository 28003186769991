import React from "react";
import { useNavigate } from "react-router";

const HeaderWithLogo = () => {
  const navigate = useNavigate();

  return (
    <div id="header-with-logo" className="flex bg-white p-5 border-b">
      <img
        src="/assets/website_logo.png"
        className="w-44 h-14 cursor-pointer"
        onClick={() => {
          navigate("/");
        }}
        alt="Company Logo"
      />
    </div>
  );
};

export default HeaderWithLogo;
