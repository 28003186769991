import * as types from "../config/actionTypes";

export const setUserData = (payload) => {
  return {
    type: types.SET_USER_DATA,
    payload,
  };
};

export const resetUserData = () => {
  return {
    type: types.RESET_USER_DATA,
  };
};
