import React, { useLayoutEffect, useState } from "react";
import Footer from "../components/footer";
import HeaderWithLogo from "../components/header-with-logo";

export const WithLogoHeaderFooter = (Component) => {
  function NewComponent(props) {
    const [headerFooterHeight, setHeaderFooterHeight] = useState(0);
    useLayoutEffect(() => {
      const headerHeight =
        document?.getElementById("header-with-logo")?.offsetHeight;
      const footerHeight = document.getElementById("footer")?.offsetHeight;
      setHeaderFooterHeight(headerHeight + footerHeight);
    }, []);
    return (
      <>
        <HeaderWithLogo />
        <div
          className="pb-16"
          style={{ minHeight: `calc(100vh - ${headerFooterHeight}px)` }}
        >
          <Component {...props} />
        </div>
        <Footer />
      </>
    );
  }
  return NewComponent;
};
