import { Button, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { WithLogoHeaderFooter } from "../../layouts/with-logo-header-footer";
import { forgotPassword } from "../../services";

const UserForgotPassword = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();

  return (
    <div className="flex flex-col md:w-[35%] mx-auto px-5 md:px-0 pt-40 md:pt-20">
      <p className="text-4xl text-[#053361] font-bold">Password help!</p>
      <p className="text-base font-normal text-[#565D65] mt-2">
        Enter the email address for your account, and we’ll send a one-time
        verification code to reset your password.
      </p>
      <div className="w-full mt-5">
        <Formik
          initialValues={{ email: "" }}
          enableReinitialize={true}
          validate={async (values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={async (values) => {
            setError("");
            forgotPassword(values).then((res) => {
              if (res.status === 200) {
                navigate("/verify-otp", { state: { email: values.email } });
              } else {
                setError(res?.message);
                return;
              }
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="flex flex-col">
              <p className="text-left text-base mt-5">Email</p>
              <TextField
                name="email"
                onChange={handleChange}
                value={values?.email}
                onBlur={handleBlur}
                className="my-2"
              />
              {errors?.email && touched?.email && (
                <p className="text-left text-xs text-red-700">
                  {errors?.email}
                </p>
              )}
              <div className="mt-5 w-full">
                <Button
                  variant="outlined"
                  type="submit"
                  className="w-full"
                  disabled={isSubmitting}
                >
                  SEND CODE
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {error && <p className="text-sm text-red-700 mt-2">{error}</p>}
    </div>
  );
};

export default WithLogoHeaderFooter(UserForgotPassword);
