import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { updateCarFavourite } from "../services";

const CarDetailsCard = ({ data, getCarList, isLoggedIn = false, isFilterBarOpen, isOnFavouritePage }) => {
  const navigate = useNavigate();

  const isTablet = window.innerWidth <= 1024 && window.innerWidth > 640;
  const isMobile = window.innerWidth <= 640;

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault(); // Prevent the default context menu from showing

      // Calculate the position of your custom context menu based on the mouse click
      const menu = document.getElementById('custom-context-menu');
      menu.style.top = `${e.clientY}px`;
      menu.style.left = `${e.clientX}px`;

      // Show your custom context menu
      menu.style.display = 'block';
    };

    // Add event listeners when the component mounts
    window.addEventListener('contextmenu', handleContextMenu);

    // Remove event listeners when the component unmounts
    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  const leftClickHandler = () => {
    navigate(`/cardetails/${data?._id}`, {
      state: { isLoggedIn },
    });
  };

  const rightClickHandler = (e) => {
    window.open(`/cardetails/${data?._id}`, '_blank', 'noopener,noreferrer');
  };

  return (
    <div 
    id="custom-context-menu"
    className="flex flex-col border-[1px] border-solid border-[#dedfe0] rounded custom-context-menu" >
      <div className="relative">
        <div className="flex absolute top-1 left-1 z-[2]" style={isMobile ? isFilterBarOpen ? {width:"100%"} : isOnFavouritePage ? {width:"100%"} : {width:"1rem",top:"-0.2rem"}  : {width:"100%"}} >
          {data?.isFavorite ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="h-6 w-6 cursor-pointer fill-red-600"
              onClick={() => {
                updateCarFavourite({ carId: data?._id }).then((res) => {
                  if (res.status === 201) {
                    getCarList();
                  }
                });
              }}
            >
              <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="h-6 w-6 fill-white bg-transparent cursor-pointer"
              onClick={() => {
                if (!isLoggedIn) {
                  navigate("/signin");
                } else {
                  updateCarFavourite({ carId: data?._id }).then((res) => {
                    if (res.status === 201) {
                      getCarList();
                    }
                  });
                }
              }}
            >
              <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z" />
            </svg>
          )}
        </div>
        <img
          src={
            data?.vehicleImages?.length
              ? data?.vehicleImages?.[0]
              : "/assets/no-image.jpg"
          }
          className={`rounded-t cursor-pointer ${
            data?.vehicleImages?.length <= 0 ? "min-w-[288px]" : ""
          }`}
          style={!isFilterBarOpen ? !isMobile ? {height:"100%",width:"100%"} : isOnFavouritePage ? {height:"100%",width:"100%"} : {height:"100px",width:"100%"}: null}
          alt="Car pic"
          onClick={() => {
            navigate(`/cardetails/${data?._id}`, {
              state: { isLoggedIn },
            });
          }}
        />
      </div>
      <div className={`text-left border-b-[1px] ${isMobile ? isFilterBarOpen ? 'p-3' : isOnFavouritePage ? 'p-3' : 'p-2' : 'p-2'}`}>
        <p
        //give height to text
          className="text-[#1871c9] leading-3 cursor-pointer"
          style={ isMobile ? isFilterBarOpen ? {fontSize: "16px", lineHeight: "1.1rem"} : isOnFavouritePage ? {fontSize: "16px", lineHeight: "1.1rem"} : {fontSize: "0.7rem", lineHeight: "0.8rem"} :{fontSize: "16px", lineHeight: "1.1rem"}}
          onClick={leftClickHandler}
          onAuxClick={rightClickHandler}
   
          
        >{`${data?.model} ${data?.makerDetail?.maker} ${data?.makerDetail?.model} ${data?.makerDetail?.grade}`}</p>
        {/* <p className="text-[#1871c9] leading-5 mt-1 cursor-pointer">
          {data?.makerDetail?.grade}
        </p> */}
        <p className="mt-2" style={isMobile ? isFilterBarOpen? {fontSize:"16px"} : isOnFavouritePage ? {fontSize:"16px"} : {fontSize:"0.7rem",marginTop:"0rem"} :  {fontSize:"16px"} }>{data?.mileage}</p>
      </div>
      {data?.vehicleBodyColor && (
        <p className={`text-left   ${isMobile ? isFilterBarOpen? 'mt-3 mx-3' :  isOnFavouritePage ? 'mt-3 mx-3' : 'mt-1 mx-2' : 'mt-3 mx-3'}`} style={isMobile ? isFilterBarOpen? {fontSize:"0.75rem",lineHeight:"1rem"} : isOnFavouritePage ?  {fontSize:"0.75rem",lineHeight:"1rem"} :  {fontSize:"0.5rem",lineHeight:"0.7rem"} : {fontSize:"0.75rem",lineHeight:"1rem"}}>{`Color: ${data?.vehicleBodyColor}`}</p>
      )}
      <p className={`text-left  ${isMobile ? isFilterBarOpen? 'mt-3 mx-3' : isOnFavouritePage ? 'mt-3 mx-3' : 'mt-1 mx-2' : 'mt-3 mx-3'}`} style={isMobile ? isFilterBarOpen? {fontSize:"0.75rem",lineHeight:"1rem"} : isOnFavouritePage ?  {fontSize:"0.75rem",lineHeight:"1rem"} : {fontSize:"0.5rem",lineHeight:"0.7rem"} : {fontSize:"0.75rem",lineHeight:"1rem"}}>{`Bidding Date: ${data?.bidDetail?.bidDate}`}</p>
      <p className={`text-left  ${isMobile ? isFilterBarOpen? 'mt-3 mx-3' : isOnFavouritePage ? 'mt-3 mx-3' : 'mt-1 mx-2' : 'mt-3 mx-3'}`} style={isMobile ? isFilterBarOpen? {fontSize:"0.75rem",lineHeight:"1rem"} : isOnFavouritePage ?  {fontSize:"0.75rem",lineHeight:"1rem"} : {fontSize:"0.5rem",lineHeight:"0.7rem"} : {fontSize:"0.75rem",lineHeight:"1rem"}}>{`Auction: ${data?.bidDetail?.bidSite}`}</p>
      <p className={`text-left  ${isMobile ? isFilterBarOpen? 'mt-3 mx-3' : isOnFavouritePage ? 'mt-3 mx-3' : 'mt-1 mx-2' : 'mt-3 mx-3'}`} style={isMobile ? isFilterBarOpen? {fontSize:"0.75rem",lineHeight:"1rem"} : isOnFavouritePage ?  {fontSize:"0.75rem",lineHeight:"1rem"} : {fontSize:"0.5rem",lineHeight:"0.7rem"} : {fontSize:"0.75rem",lineHeight:"1rem"}}>{`Bid No: ${data?.bidDetail?.bidNo}`}</p>
      {data?.inspectionScore && (
        <p className={`text-left ${isMobile ? isFilterBarOpen? 'm-3' : isOnFavouritePage ? 'm-3' : 'mx-2 mt-1 mb-1' : 'mt-3 mx-3 mb-3'}`} style={isMobile ? isFilterBarOpen? {fontSize:"0.75rem",lineHeight:"1rem"} : isOnFavouritePage ?  {fontSize:"0.75rem",lineHeight:"1rem"} : {fontSize:"0.5rem",lineHeight:"0.7rem"} : {fontSize:"0.75rem",lineHeight:"1rem"}   }>{`Inspection Score: ${data?.inspectionScore}`}</p>
      )}
    </div>
  );
};

export default CarDetailsCard;
