import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CarListing from "./screens/car-listing";
import CarDetails from "./screens/car-details";
import UserSignup from "./screens/user/signup";
import UserSignin from "./screens/user/signin";
import UserForgotPassword from "./screens/user/forgot-password";
import UserVerifyOTP from "./screens/user/verify-otp";
import UserResetPassword from "./screens/user/reset-password";
import ProfileSetting from "./screens/profile-setting";
import Favourites from "./screens/user/favorites";


const router = createBrowserRouter([
  {
    path: "/",
    // element: <HomePage />,
    element: <CarListing />,
  },
  {
    path: "/car-listing",
    element: <CarListing />,
  },
  {
    path: "/cardetails/:id",
    element: <CarDetails />,
  },
  {
    path: "/signup",
    element: <UserSignup />,
  },
  {
    path: "/signin",
    element: <UserSignin />,
  },
  {
    path: "/forgot-password",
    element: <UserForgotPassword />,
  },
  {
    path: "/verify-otp",
    element: <UserVerifyOTP />,
  },
  {
    path: "/reset-password",
    element: <UserResetPassword />,
  },
  {
    path: "/profile-setting",
    element: <ProfileSetting />,
  },
  {
    path: "/favorites",
    element: <Favourites />,
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
