import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import crossIcon from "../assets/cross-icon.png"

const filterParameters = [
  {
    key: "maker_name",
    label: "Make",
  },
  {
    key: "model",
    label: "Model",
  },
  {
    key: "grade",
    label: "Grade",
  },
  {
    key: "year",
    label: "Year",
  },
  {
    key: "color",
    label: "Color",
  },
  {
    key: "transmission",
    label: "Transmission",
  },
];

let transmissionOptions = [ "Automatic", "Manual" ];

const FilterBar = ({
  carFiltersList,
  filter,
  setFilterString,
  reloadCarList,
  clearFilters,
  setIsFilterBarOpen,
  isFilterBarOpen
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});
  const [availableOptions, setAvailableOptions] = useState([]);
  const [totalFilters, setTotalFilters] = useState([]);
  const [yearRange, setYearRange] = useState({
    from: Number(availableOptions?.[0]),
    to: Number(availableOptions?.[availableOptions.length - 1]),
    // from: 2000,
    // to: new Date().getFullYear(),
    // to: new Date().getFullYear(),
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  console.log("availableOptions", availableOptions);
  console.log("totalFilters", totalFilters);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  let isTablet = windowWidth < 1024 && windowWidth >= 768;
  let isMobile = windowWidth < 768;

  // console.log("yearRange", yearRange);
  // console.log("availableOptions", availableOptions);

  const handleFilterSelect = (category, checked, filter,optionArray) => {
    let filterToModify = JSON.parse(JSON.stringify(appliedFilters));
    if (!checked) {
      const index = filterToModify?.[category].indexOf(filter);
      if (index > -1) {
        filterToModify?.[category].splice(index, 1);
      }
    } else {
      if (filterToModify?.[category]?.length > 0) {
        filterToModify?.[category].push(filter);
      } else {
        filterToModify = {
          ...filterToModify,
          [category]: [filter],
        };
      }
    }
    reloadCarList();  
    setAppliedFilters(filterToModify);
  };

  const handleRangeFilterSelect = (category, range) => {
    let filterToModify = JSON.parse(JSON.stringify(appliedFilters));
    filterToModify = {
      ...filterToModify,
      [category]: [range?.from, range?.to],
    };
    setAppliedFilters(filterToModify);
  };

  useEffect(() => {
    let filterString = "";
    let totalFilters = [];
    Object.keys(appliedFilters).forEach((filterType) => {
      if (appliedFilters?.[filterType]?.length > 0) {
        filterString +=
          `&filter[${filterType}]=` +
          JSON.stringify(appliedFilters?.[filterType]);
        const filters = appliedFilters?.[filterType];
        if (filterType === "year") {
          totalFilters.push({
            type: filterType,
            value:
              filters[0] === filters[1]
                ? filters[0]
                : filters[0] + "-" + filters[1],
          });
        }else {
          filters?.forEach((value) => {
            totalFilters.push({
              type: filterType,
              value: value,
            });
          });
        }
      }
    });
    setTotalFilters(totalFilters);
    setFilterString(filterString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilters]);

  useEffect(() => {
    if (selectedFilter?.key === "year") {
      if (isNaN(yearRange.from)) {
        setYearRange({
          from: Number(availableOptions?.[0]),
          to: Number(availableOptions?.[availableOptions.length - 1]),
        });
      }
    }

  }, [selectedFilter]);

        
          
        

  useEffect(() => {
    let filterArray = filter.split("&filter");
    let apf = {};
    filterArray.map((fd) => {
      if (fd !== "") {
        let kv = fd.split("=");
        let kvKey = kv[0].replace("[", "").replace("]", "");
        let kvValue = JSON.parse(kv[1]);

        apf[kvKey] = kvValue;
        if (kvKey === "year") {
            setYearRange({
              from: kvValue[0],
              to: kvValue[1],
            });
        }
      }
    });
    setAppliedFilters(apf);
  }, []);

  const handleRemoveFilter = (category, value) => {
    let filterToModify = JSON.parse(JSON.stringify(appliedFilters));
    if (category === "year") {
      filterToModify = {
        ...filterToModify,
        [category]: [],
      };
      setYearRange({
        from: Number(availableOptions?.[0]),
        to: Number(availableOptions?.[availableOptions.length - 1]),
      });
    } else {
      const index = filterToModify?.[category].indexOf(value);
      if (index > -1) {
        filterToModify?.[category].splice(index, 1);
      }
    }
    reloadCarList();
    setAppliedFilters(filterToModify);
  };

  return (
    <div className="flex flex-[0.45] lg:flex-[0.2] border-r-[1px] h-full">
      <div className="flex flex-col w-full h-screen">
        <div className="flex items-center space-between place-content-around p-3 border-b-[1px] sticky top-0 bg-white">
          <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            className="fill-[#6d747a] h-6 w-6"
          >
            <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
          <p className={`${isMobile ? 'flex flex-col text-[11px] ml-2' : 'flex ml-3 text-xs leading-6'} text-[#6d747a] font-bold`}>
            {`FILTER & SORT ${
              totalFilters?.length > 0 ? `( ${totalFilters?.length})` : ""
            }`}
            {totalFilters?.length ? (
              <p
                className={`underline ml-1 font-bold cursor-pointer`}
                onClick={() => {
                  setAppliedFilters({});
                  clearFilters()
                }}
              >
                Clear filter
              </p>
            ) : (
              ""
            )}
          </p>
          {isTablet || isMobile && <img src={crossIcon} style={{width:"10px",height:"10px",filter:"contrast(0%)",marginLeft:"15px"}} alt="cross-icon" className="ml-auto cursor-pointer" onClick={() => setIsFilterBarOpen(false)} />}
        </div>
        <div className="flex gap-2 p-3 flex-wrap">
          {totalFilters?.map((filter) => {
            return (
              <div className="flex items-center bg-blue-700 p-1 px-2 rounded-r-2xl rounded-l-2xl">
                <p className="text-xs font-normal text-white">{filter.value}</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  focusable="false"
                  className="ml-1 cursor-pointer"
                  onClick={() => {
                    handleRemoveFilter(filter.type, filter.value);
                  }}
                >
                  <path
                    d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
                    fill="#ffffff"
                  ></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </div>
            );
          })}
        </div>
        <div
          className={`flex flex-col overflow-hidden ${
            openModal ? "hidden" : "flex"
          }`}
        >
          {filterParameters?.map((item) => {
            return (
              <div
                key={item.key}
                className={`flex border-b-[1px] border-[#c8cacc] items-center justify-between p-4 px-5 hover:text-blue-300 hover:fill-blue-300 cursor-pointer`}
                onClick={() => {
                  setOpenModal(true);
                  setSelectedFilter(item);
                  setAvailableOptions(carFiltersList[item.key]);
                }}
              >
                <p className="leading-4 font-normal">{item.label}</p>
                <svg
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  className="h-6 w-6"
                >
                  <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path>
                  <path d="M0-.25h24v24H0z" fill="none"></path>
                </svg>
              </div>
            );
          })}
        </div>
        <div
          className={`bg-white ${
            openModal ? "flex" : "hidden"
          } flex-col duration-300 overflow-hidden`}
        >
          <div
            className="flex cursor-pointer w-full items-center p-3 pt-0 border-b-[1px] sticky top-8 bg-white"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <svg
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              className="fill-blue-300 h-6 w-6"
            >
              <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"></path>
              <path d="M0-.5h24v24H0z" fill="none"></path>
            </svg>
            <p className="text-blue-300 ml-3">{selectedFilter.label}</p>
          </div>
          <div className="flex flex-col p-3 gap-1 overflow-auto">

            {selectedFilter?.key === "year" ? (
              <div className="flex justify-center pt-10">
                {Number(availableOptions?.[0]) || 0}
                <Box sx={{ width: 250, mx: 2 }}>
                  <Slider
                    className="w-[90%]"
                    value={[yearRange?.from, yearRange?.to]}
                    min={Number(availableOptions?.[0]) || 0}
                    /* max={
                      Number(
                        availableOptions?.[availableOptions?.length - 1]
                      ) || 100
                    } */
                    // max={new Date().getFullYear()}
                    max={2000}
                    valueLabelDisplay="auto"
                    onChange={(event) => {
                      const newValue = event?.target?.value;
                      setYearRange({
                        from: Number(newValue[0]),
                        to: Number(newValue[1]),
                      });
                      handleRangeFilterSelect(selectedFilter?.key, {
                        from: Number(newValue[0]),
                        to: Number(newValue[1]),
                      });
                    }}
                  />
                </Box>
                {/* {new Date().getFullYear()} */}
                2000
              </div>
            ) : selectedFilter?.key === "transmission" ? (<div className="flex flex-col gap-2 mt-8">
              {transmissionOptions?.map((option,index) => {
                  return(
                    <div
                    className="flex items-center justify-between p-2 px-3 border-[1px] border-solid border-[#c8cacc] rounded-lg cursor-pointer hover:text-blue-300 hover:fill-blue-300"
                    onClick={() => {
                      handleFilterSelect(
                        selectedFilter?.key, //transmission
                        appliedFilters?.[selectedFilter?.key]?.includes(option) //Automatic
                          ? false
                          : true,
                          option
                      );
                    }}
                  >
                    <p className={`text-xs font-normal ${appliedFilters?.[selectedFilter?.key]?.includes(option) && "text-blue-700" }`}>{option}</p>
                    <svg
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      focusable="false"
                      className={`h-6 w-6 ${
                        appliedFilters?.[selectedFilter?.key]?.includes(option)
                          ? "fill-blue-700"
                          : ""
                      }`}
                    >
                      <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path>
                      <path d="M0-.25h24v24H0z" fill="none"></path>
                    </svg>
                  </div>
                  )
              })}
            </div>) : (
              <div className="flex flex-col gap-2 mt-8">

              {availableOptions?.map((option) => {
                return (
                  <div
                    className="flex items-center justify-between p-2 px-3 border-[1px] border-solid border-[#c8cacc] rounded-lg cursor-pointer hover:text-blue-300 hover:fill-blue-300"
                    onClick={() => {
                      handleFilterSelect(
                        selectedFilter?.key,
                        appliedFilters?.[selectedFilter?.key]?.includes(option)
                          ? false
                          : true,
                        option
                      );
                    }}
                  >
                    <p className={`text-xs font-normal ${appliedFilters?.[selectedFilter?.key]?.includes(option) && "text-blue-700" }`}>{option}</p>
                    <svg
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      focusable="false"
                      className={`h-6 w-6 ${
                        appliedFilters?.[selectedFilter?.key]?.includes(option)
                          ? "fill-blue-700"
                          : ""
                      }`}
                    >
                      <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path>
                      <path d="M0-.25h24v24H0z" fill="none"></path>
                    </svg>
                  </div>
                );
              })
              }
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
