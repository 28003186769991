import React, { useState } from "react";
import axios from "axios";
// Import the main component
import { Viewer, Worker } from "@react-pdf-viewer/core";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import pdfWorker from "pdfjs-dist/build/pdf.worker.entry";


const SinglePagePdf = (props) => {
  const isTablet = window.innerWidth <= 1024 && window.innerWidth > 640;
  const isMobile = window.innerWidth <= 640;

  //   const url = "https://cors-anywhere.herokuapp.com/" + props.src;
  const url = "" + props.src;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  console.log("PDFprops",props)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  async function loadUrl(url) {
    let response = await axios.get(url);
    if (response.status === 200) {
      // response.data
      var len = response.data.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = response.data.charCodeAt(i);
      }

      return { data: JSON.parse(bytes.buffer).data };
    }
    return url;
  }

  return (
    <div>
      <div
        style={{
          border: "1px solid rgba(0, 0, 0, 0.3)",
          width: `${isMobile ? "350px" : "650px"}`,
          height: `${isMobile ? "450px" : "750px"}`,
        }}
      >
        <Worker workerUrl={pdfWorker}>
          <Viewer fileUrl={url} />
        </Worker>
      </div>
    </div>
  );
};

export default SinglePagePdf;
