import * as types from "../config/actionTypes";
const initialState = {
  search: "",
  filter: "",
};

export const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case types.RESET_SEARCH:
      return {
        ...state,
        search: initialState.search
      };
    case types.SET_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case types.RESET_FILTER:
      return {
        ...state,
        filter: initialState.filter
      };
    default:
      return state;
  }
};
