import { Button, TextField } from "@mui/material";
import { Formik } from "formik";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { WithLogoHeaderFooter } from "../../layouts/with-logo-header-footer";
import { forgotPassword, verifyOTP } from "../../services";

const UserVerifyOTP = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [error, setError] = useState("");
  const [resentOtpText, setResentOtpText] = useState("");
  const resendOTP = () => {
    setError("");
    forgotPassword({ email: state?.email }).then((res) => {
      if (res.status === 200) {
        setResentOtpText("Resent OTP");
      } else {
        setError(res?.message);
        return;
      }
    });
  };
  return (
    <div className="md:w-[35%] mx-auto px-5 md:px-0 pt-10">
      {resentOtpText && (
        <p className="text-base text-green-500 mb-4">{resentOtpText}</p>
      )}
      <p className="text-4xl text-[#053361] font-bold mt-7">
        Enter your verification code
      </p>
      <p className="text-base font-normal text-[#565D65] mt-2">
        We've sent your code to {state?.email}. Please copy the code to the
        input box below.
      </p>
      <div className="w-full mt-5">
        <Formik
          initialValues={{ otp: "" }}
          validate={async (values) => {
            const errors = {};
            if (!values.otp || values?.otp?.length !== 6) {
              errors.otp = "Please enter valid otp";
            }
            return errors;
          }}
          onSubmit={async (values) => {
            setError("");
            verifyOTP(values).then((res) => {
              if (res.status === 200) {
                navigate("/reset-password");
              } else {
                setError(res.message);
              }
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} className="flex flex-col">
              <p className="text-left text-base">Verification Code</p>
              <TextField
                name="otp"
                onChange={handleChange}
                value={values?.otp}
                onBlur={handleBlur}
                className="my-2 w-full"
              />
              {errors?.otp && touched?.otp && (
                <p className="text-left text-xs text-red-700">{errors?.otp}</p>
              )}
              <div className="mt-5 w-full">
                <Button
                  variant="outlined"
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full"
                >
                  VERIFY
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
      {error && <p className="text-sm text-red-700 mt-2">{error}</p>}
      <div className="flex flex-row justify-center mt-5">
        <p>Didn't get a code?</p>
        <p
          className="text-blue-500 ml-1 cursor-pointer"
          onClick={() => {
            resendOTP();
          }}
        >
          Send another code
        </p>
      </div>
    </div>
  );
};

export default WithLogoHeaderFooter(UserVerifyOTP);
