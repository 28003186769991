import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router";
import Footer from "../components/footer";
import Header from "../components/header";

export const WithUserMenu = (Component) => {
  function WrappedComponent(props) {
    const navigate = useNavigate();
    const [headerFooterHeight, setHeaderFooterHeight] = useState(0);
    useLayoutEffect(() => {
      const headerHeight = document?.getElementById("header")?.offsetHeight;
      const footerHeight = document.getElementById("footer")?.offsetHeight;
      setHeaderFooterHeight(headerHeight + footerHeight);
    }, []);
    return (
      <>
        <Header />
        <div
          className="flex flex-row"
          style={{ minHeight: `calc(100vh - ${headerFooterHeight}px)` }}
        >
          <div className="hidden md:flex flex-[0.2] flex-col border-r p-4">
            <p className="text-base text-left text-[#053361] font-bold">
              Hello,
            </p>
            <p className="text-4xl text-left text-[#053361] font-bold">
              {props?.user?.firstName}
            </p>
            <div className="my-3 p-3 hover:bg-gray-100 cursor-pointer rounded-sm">
              <p
                className="text-left text-[#053361]"
                onClick={() => {
                  navigate("/favoutires");
                }}
              >
                My Favorites
              </p>
            </div>
            <div className="border-b" />
            <div className="my-3 p-3 hover:bg-gray-100 cursor-pointer rounded-sm">
              <p
                className="text-left text-[#053361]"
                onClick={() => {
                  navigate("/profile-setting");
                }}
              >
                Profile Setting
              </p>
            </div>
            <div className="p-3 hover:bg-gray-100 cursor-pointer rounded-sm">
              <p
                className="text-left text-[#053361]"
                onClick={() => {
                  props.resetUserData();
                  window.sessionStorage.clear();
                  navigate("/");
                  window.location.reload();
                }}
              >
                Sign out
              </p>
            </div>
          </div>
          <Component {...props} />
        </div>
        <Footer />
      </>
    );
  }
  return WrappedComponent;
};
