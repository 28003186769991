import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { setUserData } from "../../actions/userActions";
import { WithLogoHeaderFooter } from "../../layouts/with-logo-header-footer";
import { signin } from "../../services";

const UserSignin = (props) => {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("props", props);

  return (
    <div className="md:w-[35%] mx-auto px-5 md:px-0 pt-10">
      {state?.message && (
        <p className="text-base text-green-500 mb-4">{state?.message}</p>
      )}
      <p className="text-4xl text-[#053361] font-bold">Welcome!</p>
      <p className="text-base font-normal text-[#565D65] mt-2">
        Sign in to access your account.
      </p>
      <div className="w-full mt-5">
        <Formik
          initialValues={{ email: "", password: "" }}
          enableReinitialize={true}
          validate={async (values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            if (!values.password) {
              errors.password = "Please enter password";
            }
            return errors;
          }}
          onSubmit={async (values) => {
            setError("");
            signin(values).then((res) => {
              console.log("resPonse", res);
              if (res?.status === 200) {
                props.setUserData({
                  ...res.data,
                  isLoggedIn: true,
                });
                navigate("/");
              } else {
                setError(res?.message);
              }
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="flex flex-col">
              <p className="text-left text-base mt-5">Email</p>
              <TextField
                name="email"
                onChange={handleChange}
                value={values.email}
                onBlur={handleBlur}
                className="my-2"
              />
              {errors?.email && touched?.email && (
                <p className="text-left text-xs text-red-700">{errors.email}</p>
              )}
              <p className="text-left text-base mt-5">Password</p>
              <TextField
                name="password"
                onChange={handleChange}
                value={values.password}
                type="password"
                className="my-2"
              />
              {errors?.password && touched?.password && (
                <p className="text-left text-xs text-red-700">
                  {errors.password}
                </p>
              )}
              <div className="mt-5 w-full">
                <Button
                  variant="outlined"
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full"
                >
                  SIGN IN
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {error && <p className="text-sm text-red-700 mt-2">{error}</p>}
      <p
        className="text-blue-500 cursor-pointer mt-5"
        onClick={() => {
          navigate("/forgot-password");
        }}
      >
        Forgot your password?
      </p>
      <div className="flex flex-row justify-center mt-5">
        <p>Don't have an account?</p>
        <p
          className="text-blue-500 ml-1 cursor-pointer"
          onClick={() => navigate("/signup")}
        >
          Create an account
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithLogoHeaderFooter(UserSignin));
