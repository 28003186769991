import React, { useEffect, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { Button, Fade, Paper, Popper } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { resetUserData, setUserData } from "../actions/userActions";
import { resetSearchFromState, resetFilterFromState } from "../actions/searchBarActions";
import { pathOr } from "ramda";

const Header = ({ setSearchString, user, resetUserData, searchString }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(searchString || "");
  
  useEffect(() => {
    if(searchString){
    setSearch(searchString)
    }
  }, [searchString]);

  const searchHandler = () => {
      if (location?.pathname !== "/car-listing") {
        setSearchString(search);
        navigate("/car-listing", { state: { search: search } });
      } else {
        setSearchString(search);
      }
  }


  return (
    <>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
        className="z-[3]"
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <div className="flex flex-col py-4">
                <div className="p-4 px-6">
                  <Button variant="outlined">MAKE A PAYMENT</Button>
                </div>
                {!user.isLoggedIn && (
                  <div
                    className="mt-1 p-2 px-6 cursor-pointer hover:bg-gray-100"
                    onClick={() => navigate("/signin")}
                  >
                    <p>Sign In</p>
                  </div>
                )}
                {!user.isLoggedIn && (
                  <div
                    className="mt-1 p-2 px-6 cursor-pointer hover:bg-gray-100"
                    onClick={() => navigate("/signup")}
                  >
                    <p>Register</p>
                  </div>
                )}
                {user.isLoggedIn && (
                  <div
                    className="mt-1 p-2 px-6 cursor-pointer hover:bg-gray-100"
                    onClick={() => {
                      navigate("/favorites");
                    }}
                  >
                    <p>My Favorites</p>
                  </div>
                )}
                {user.isLoggedIn && (
                  <div
                    className="mt-1 p-2 px-6 cursor-pointer hover:bg-gray-100"
                    onClick={() => {
                      navigate("/profile-setting");
                    }}
                  >
                    <p>Profile Setting</p>
                  </div>
                )}
                {user.isLoggedIn && (
                  <div
                    className="mt-1 p-2 px-6 cursor-pointer hover:bg-gray-100"
                    onClick={() => {
                      resetUserData();
                      window.sessionStorage.clear();
                      navigate("/");
                      window.location.reload();
                    }}
                  >
                    <p className="text-red-700">Sign out</p>
                  </div>
                )}
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>

      <div
        id="header"
        className="flex flex-col bg-white px-3 lg:px-28 py-3 shadow-[0_4px_8px_0_rgba(42,25,61,0.16)]"
      >
        <div className="flex flex-row items-center justify-between">
          <div className="flex items-center">
            <img
              src="/assets/website_logo.png"
              className="w-44 h-14 cursor-pointer"
              onClick={() => {
                navigate("/");
                if(setSearchString){
                  setSearchString("")
                }
                setSearch("");
              }}
              alt="Company Logo"
            />
          </div>
          <div
            className="flex items-center justify-center hover:bg-blue-300 h-8 w-8 rounded-2xl"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setOpen((prev) => !prev);
            }}
          >
            <svg
              className="header-footer-icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          </div>
        </div>
        <div className="flex mt-5">
          <OutlinedInput
            className="w-full"
            placeholder="Search by make, model, or keyword"
            value={search}
            endAdornment={
              <InputAdornment position="end">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  className="cursor-pointer"
                  onClick={searchHandler}
                >
                  <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </InputAdornment>
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (location?.pathname !== "/car-listing") {
                  setSearchString(search)
                  navigate("/car-listing", { state: { search: search } });
                } else setSearchString(search);
              }
            }}
            onChange={(event) => {
              setSearch(event?.target?.value);
            }}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: pathOr({}, ["user"], state),
    searchString: pathOr("", ["searchBar", "search"], state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
    resetUserData: () => {
      dispatch(resetUserData());
      dispatch(resetSearchFromState());
      dispatch(resetFilterFromState());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
