import { createStore, applyMiddleware } from "redux";
import rootReducers from "./reducers/rootReducers";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

const logger = createLogger({});

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(logger))
);

export const persistor = persistStore(store);
