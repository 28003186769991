import { Button, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { WithLogoHeaderFooter } from "../../layouts/with-logo-header-footer";
import { signup } from "../../services";

const UserSignup = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");

  return (
    <div className="md:w-[35%] mx-auto px-5 md:px-0 py-10">
      <p className="text-4xl text-[#053361] font-bold mt-7">
        Create an account
      </p>
      <p className="text-base font-normal text-[#565D65] mt-2">
        To continue, please create an account.
      </p>
      <div className="w-full mt-5">
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            password: "",
          }}
          enableReinitialize={true}
          validate={async (values) => {
            const errors = {};
            if (!values?.firstName) {
              errors.firstName = "Please enter first name";
            }
            if (!values.lastName) {
              errors.lastName = "Please enter last name";
            }
            if (!values.email) {
              errors.email = "Please enter email";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            if (!values.password) {
              errors.password = "Please enter password";
            } else if (
              !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{6,}$/i.test(
                values.password
              )
            ) {
              errors.password = "Please enter password that contains atleast 6 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character";
            }
            return errors;
          }}
          onSubmit={async (values) => {
            setError("");
            signup(values).then((res) => {
              if (res?.status === 201) {
                navigate("/signin", {
                  state: {
                    message: "Account created Successfully, Please wait for owner to verify your account",
                  },
                });
              } else {
                setError(res?.message);
              }
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="flex flex-col">
              <p className="text-left text-base">First Name</p>
              <TextField
                name="firstName"
                onChange={handleChange}
                value={values.firstName}
                onBlur={handleBlur}
                className="my-2 w-full"
              />
              {errors?.firstName && touched?.firstName && (
                <p className="text-left text-xs text-red-700">
                  {errors.firstName}
                </p>
              )}
              <p className="text-left text-base">Last Name</p>
              <TextField
                name="lastName"
                onChange={handleChange}
                value={values.lastName}
                onBlur={handleBlur}
                className="my-2 w-full"
              />
              {errors?.lastName && touched?.lastName && (
                <p className="text-left text-xs text-red-700">
                  {errors.lastName}
                </p>
              )}
              <p className="text-left text-base mt-5">Email</p>
              <TextField
                name="email"
                onChange={handleChange}
                value={values.email}
                onBlur={handleBlur}
                className="my-2"
              />
              {errors?.email && touched?.email && (
                <p className="text-left text-xs text-red-700">{errors.email}</p>
              )}
              <p className="text-left text-base mt-5">Password</p>
              <TextField
                name="password"
                onChange={handleChange}
                value={values.password}
                type="password"
                className="my-2"
              />
              {errors?.password && touched?.password && (
                <p className="text-left text-xs text-red-700">
                  {errors.password}
                </p>
              )}
              <p className="text-left text-base mt-5">Confirm Password</p>
              <TextField
                name="confirmPassword"
                onChange={handleChange}
                value={values.confirmPassword}
                type="password"
                className="my-2"
              />
              <div className="mt-5 w-full">
                <Button
                  variant="outlined"
                  type="submit"
                  className="w-full"
                  disabled={isSubmitting}
                >
                  CREATE ACCOUNT
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {error && <p className="text-sm text-red-700 mt-2">{error}</p>}
      <div className="flex flex-row justify-center mt-5">
        <p>Already have an account?</p>
        <p
          className="text-blue-500 ml-1 cursor-pointer"
          onClick={() => navigate("/signin")}
        >
          Sign In
        </p>
      </div>
    </div>
  );
};

export default WithLogoHeaderFooter(UserSignup);
