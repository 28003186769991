import * as types from "../config/actionTypes";

export const setSearchInState = (payload) => {
  return {
    type: types.SET_SEARCH,
    payload,
  };
};

export const resetSearchFromState = () => {
  return {
    type: types.RESET_SEARCH,
  };
};

export const setFilterInState = (payload) => {
  return {
    type: types.SET_FILTER,
    payload,
  };
};

export const resetFilterFromState = () => {
  return {
    type: types.RESET_FILTER,
  };
};
