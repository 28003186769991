import React from "react";

const Loading = () => {
    return (
        <div className="my-5 flex items-center justify-center">
        <img
          src="/assets/loading-circle.gif"
          className="w-10 cursor-pointer"
          alt="Company Logo"
        />
      </div>
    )
}

export default Loading