import React, { useEffect, useState } from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, Zoom, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from "react-redux";
import withAuth from "../layouts/with-auth";
import { pathOr } from "ramda";
import { sendInspectionRequest } from "../services";


import ImageGrid from "../components/image-grid";
import {
  getCarDetails,
  getTranslatedAuctionSheet,
  updateCarFavourite,
} from "../services";
import SinglePagePdf from "../components/single-page-pdf";
import { Button } from "@mui/material";
import Loading from "../layouts/Loading";

import {
  setSearchInState,
  setFilterInState,
  resetSearchFromState,
  resetFilterFromState,
} from "../actions/searchBarActions";

const CarDetails = ({user,search,setSearchInState}) => {

  const [showToast, setShowToast] = useState(false);
  const [loadingForTranslatedAuction, setLoadingForTranslatedAuction] = useState(false);
  const [loadingForCarDetails, setLoadingForCarDetails] = useState(true);
  const [carDetails, setCarDetails] = useState({});
  const [showCarReport, setShowCarReport] = useState(false);
  const [convertedPdfurl, setConvertedPdfurl] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const [searchString, setSearchString] = useState(search || "");

  const handleSendInspectionRequest = () => {
    if(carDetails?.bidNo && carDetails?.makerDetail){
    const payload = {
      email: user.email,
      firstName:user.firstName,
      lastName: user.lastName,
      bidDetail: carDetails.bidDetail,
      markerDetail: carDetails.makerDetail,
      vehicleImage: carDetails.vehicleImages[1],
    }
    sendInspectionRequest(payload).then((res) => {
      if(res.message === "Success"){
        toast.success("Inspection request sent successfully",{
          autoClose: 5000,
          position: "top-center",
        });
      }else{
        toast.error(res?.message,{
          autoClose: 5000,
          position: "top-center",
        });
      }
    })
    }
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  let isTablet = windowWidth > 768 && windowWidth < 1024;
  let isMobile = windowWidth < 768;

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (location?.state) {
      setIsLoggedIn(location?.state?.isLoggedIn);
    }
  }, [location]);


  const handleGetCarDetails = (id) => {
    const payload = { id };
    getCarDetails(payload).then((res) => {
      if (res?.message === "Success") {
        if (res?.data.vehicleDetailImage) {
          res?.data?.vehicleImages?.unshift(res?.data.vehicleDetailImage);
        }
        setCarDetails(res?.data);
        setLoadingForCarDetails(false);
      }else{
        setLoadingForCarDetails(false);
        toast.error(`Route is not correct cardetails/${id}`);
        setCarDetails(null);
        setShowToast(true);
      }
    });
  };

  useEffect(() => {
    if(id){
      handleGetCarDetails(id);
    }
  }, [id]);

  const overviewInformation = [{
    title: "Year",
    value: carDetails?.model
  },{
    title: "Make",
    value: carDetails?.makerDetail?.maker
  },{
    title: "Transmission",
    value: carDetails?.vehicleTransmission
  },{
    title: "Inspection Score",
    value: carDetails?.inspectionScore
  },{
    title: "Model",
    value: carDetails?.vehicleType
  },{
    title: "Color",
    value: carDetails?.vehicleBodyColor
  },{
    title: "Mileage",
    value: carDetails?.vehicleMileage?.replace("(mile)", "")
  },{
    title: "Displacement",
    value: carDetails?.vehicleDisplacement
  }]

   const divGenerator = (title,value) =>  (
    <div className={`${isMobile ? 'w-[50%]' : ''}`}>
    <p className={`${isMobile ? 'text-md' : isTablet ? 'text-xl' :'text-xl'} text-[#2a343d] font-bold text-left`}>
      {value}
    </p>
    <p className={`${isMobile ? 'text-xs' : isTablet ? 'text-sm mt-1' :'text-sm mt-1'} font-normal text-[#565d65]  text-left`}>
      {title}
    </p>
  </div>
  )

  return (
    <>
      <Header
        searchString={searchString}
        setSearchString={(search) => {
          setSearchString(search)
          setSearchInState(search);
        }}
      />
      <div className="flex py-3 flex-col mb-14">
        <div className="flex justify-start px-3 lg:px-28">
          <div
            className="inline-flex cursor-pointer hover:bg-[#e0f2ff] p-3 w-auto rounded-sm"
            onClick={() => {
              navigate("/car-listing");
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              className="fill-[#005ab8] back-arrow"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.41421 10.8476L8.84758 6.41421L7.43336 5L1.29289 11.1405C0.902369 11.531 0.902369 12.1642 1.29289 12.5547L7.43336 18.6952L8.84758 17.2809L4.41421 12.8476H21.0351V10.8476H4.41421Z"
              ></path>
            </svg>
            <p className="text-[#005ab8] font-bold text-sm leading-6 ml-2">
              SEARCH
            </p>
          </div>
        </div>
        {loadingForCarDetails ? (<Loading />) : carDetails && (<>
        <div className={`${isMobile ? 'mb-0' : isTablet ? 'mb-2' : 'mb-3'} flex justify-between mt-5 px-3 lg:px-28`}>
          <div>
            <p className={`${isMobile ? 'text-[18px]' : isTablet ? 'text-[30px]' : 'text-[40px]'} leading-[24px] font-bold text-[#053361] text-left`}>{`${
              carDetails?.model || ""
            } ${carDetails?.makerDetail?.maker || ""} ${
              carDetails?.makerDetail?.model || ""
            } ${
              carDetails?.makerDetail?.grade || ""
            }`}</p>
            <p className={`text-left  ${isMobile ? 'text-sm mt-1' : isTablet ? 'text-xl' : 'text-2xl mt-4'} font-bold text-[#2a343d] `}>
              {`${carDetails?.bidDetail?.bidSite + " " || " "}Bid No. ${
                carDetails?.bidNo || ""
              }`}
            </p>
            <p className={`text-left  ${isMobile ? 'text-[12px]' : isTablet ? 'text-xl' : 'text-2xl'} font-bold text-[#2a343d] `}>
              
              {`Auction Date: ${carDetails?.bidDetail?.bidDate ? new Date(carDetails?.bidDetail?.bidDate).
              toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              }) : ""} JST`}
              
            </p>
          </div>
          {carDetails?.isFavorite ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="h-6 w-6 cursor-pointer fill-[#053361]"
              onClick={() => {
                updateCarFavourite({ carId: carDetails?._id }).then((res) => {
                  if (res.status === 201) {
                    handleGetCarDetails(id);
                  }
                });
              }}
            >
              <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="h-6 w-6 cursor-pointer fill-[#053361]"
              onClick={() => {
                if (!isLoggedIn) {
                  navigate("/signin");
                } else {
                  updateCarFavourite({ carId: carDetails?._id }).then((res) => {
                    if (res.status === 201) {
                      handleGetCarDetails(id);
                    }
                  });
                }
              }}
            >
              <path d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z" />{" "}
            </svg>
          )}
        </div>
        <ImageGrid images={carDetails?.vehicleImages} className={`${isMobile ? 'p-2 mt-1' : isTablet ? 'mt-1 p-1' : 'mt-3'}`} />
        <div className={`${isMobile ? 'my-5 flex flex-col items-center' : 'my-2'} px-3 lg:px-28`}>
          <p className={`${isMobile ? 'text-2xl flex w-full justify-center' : isTablet ? 'text-xl' :'text-3xl'} text-[#053361] font-bold text-left`}>
            Overview
          </p>
          <div style={isMobile ? {display:"flex",width:"100%",flexDirection:'column'} : {display :'flex'}}>
          <div className={`${isMobile ? 'mt-3 gap-y-6 justify-items-center' : 'mt-3 gap-y-7'} grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4  justify-evenly`} style={!isMobile ? {width:"75%"} : null}>
            {overviewInformation.map((item,index) => (divGenerator(item.title,item.value)))}
          </div>
          <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:'center',width:`${isMobile ? '100%' : '25%'}`,gap:"1rem",marginTop:`${isMobile ? '1rem' : null}`}}>
              <p className={`${isMobile ? 'text-xl' : isTablet ? 'text-xl' :'text-3xl'} text-[#053361] font-bold text-left`}>
                Request Inspection
              </p>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#4c00b0",
                  color: "#fff",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#4c00b7"
                  }
                }}
                onClick={handleSendInspectionRequest}
              >
                Send Request
              </Button>
          </div>
          </div>
        
          {carDetails?.vehicleDetailImage ? (
            <div className="mt-3 flex flex-col gap-y-7 justify-center items-center">
              <div className="my-5">
                <Button
                  variant="outlined"
                  onClick={async () => {
                    if (showCarReport) {
                      return;
                    }
                    if (
                      carDetails?.vehicleTranslatedDetailPdf?.convertedPdfurl
                    ) {
                      setConvertedPdfurl(
                        carDetails?.vehicleTranslatedDetailPdf?.convertedPdfurl
                      );
                      setShowCarReport((prev) => !prev);
                    } else {
                      setLoadingForTranslatedAuction(true);
                      toast.info("Please wait while we are fetching data",{
                        autoClose: 5000,
                        position: "top-center",
                      });
                      const payload = { id };
                      await getTranslatedAuctionSheet(payload).then((res) => {
                        if (res?.message === "Success") {
                          if (res?.data?.convertedPdfurl) {
                            setConvertedPdfurl(res?.data?.convertedPdfurl);
                            setShowCarReport((prev) => true);
                          }
                        } else {
                          toast.error(res?.message,{
                            autoClose: 5000,
                            position: "top-center",
                          });
                        }
                        setLoadingForTranslatedAuction(false);
                      });
                    }
                  }}
                >
                  {loadingForTranslatedAuction ? (
                    <div className="my-5 flex items-center justify-center">
                      <img
                        src="/assets/loading-circle.gif"
                        className="w-10 cursor-pointer"
                        alt="Company Logo"
                      />
                    </div>
                  ) : (
                    "SEE TRANSLATED AUCTION SHEET"
                  )}
                </Button>
              </div>
              {showCarReport && convertedPdfurl ? (
                <div className="my-5">
                  <div className="w-2/4">
                    <SinglePagePdf
                      src={convertedPdfurl}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        </>)}
        {!carDetails && (<div className="flex justify-center mb-3 px-3 lg:px-28 "><div>No Data Found</div></div>)}
        {showToast ? <ToastContainer autoClose={false} transition={Zoom} hideProgressBar limit={1} position="top-center" closeOnClick enableMultiContainer  /> : <ToastContainer/>}
      </div>
      <Footer />
    </>
  );
};


const mapStateToProps = (state) => {
  return {
    user: pathOr({}, ["user"], state),
    search: pathOr("", ["searchBar", "search"], state),
    filter: pathOr("", ["searchBar", "filter"], state),
    searchString: pathOr("", ["searchBar", "search"], state),

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchInState: (data) => {
      dispatch(setSearchInState(data));
    },
    setFilterInState: (data) => {
      dispatch(setFilterInState(data));
    },
    clearSearchAndFilters: (data) => {
      dispatch(resetSearchFromState());
      dispatch(resetFilterFromState());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAuth(CarDetails));

