import React from "react";

const Footer = () => {
  const renderFacebookLogo = () => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 35 34"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
      >
        <path
          fill="#fff"
          d="M17.008 0C7.613 0 0 7.616 0 17.008s7.613 17.008 17.008 17.008c9.394 0 17.009-7.615 17.009-17.008C34.017 7.615 26.401 0 17.008 0zm3.755 14.127l-.195 2.545H17.96v8.84h-3.296v-8.84h-1.759v-2.545h1.759v-1.709c0-.753.02-1.915.565-2.635.576-.762 1.368-1.28 2.729-1.28 2.218 0 3.152.316 3.152.316l-.438 2.604s-.734-.211-1.418-.211c-.685 0-1.296.244-1.296.928v1.986l2.805.100z"
        ></path>
      </svg>
    );
  };
  const renderTwitterLogo = () => {
    return (
      <svg
        width="24"
        height="24"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 35 34"
        aria-hidden="true"
      >
        <path
          fill="#fff"
          d="M17 0C7.612 0 0 7.612 0 17c0 9.39 7.611 17 17 17s17-7.608 17-17c0-9.389-7.611-17-17-17zm6.786 14.355c.007.149.009.3.009.452 0 4.619-3.513 9.941-9.939 9.941a9.888 9.888 0 0 1-5.354-1.568c.271.031.552.047.833.047a7.009 7.009 0 0 0 4.339-1.496 3.493 3.493 0 0 1-3.263-2.424 3.46 3.46 0 0 0 1.578-.063 3.49 3.49 0 0 1-2.805-3.425v-.043c.473.26 1.012.418 1.583.436a3.493 3.493 0 0 1-1.554-2.908c0-.641.172-1.24.473-1.758a9.923 9.923 0 0 0 7.201 3.65 3.494 3.494 0 0 1 5.953-3.186 6.987 6.987 0 0 0 2.219-.847c-.26.816-.813 1.5-1.536 1.933a6.986 6.986 0 0 0 2.008-.55 7.096 7.096 0 0 1-1.745 1.809z"
        ></path>
      </svg>
    );
  };
  return (
    <div id="footer" className="w-full border-t-4 border-[#4c00b0]">
      <div className="flex flex-col sm:flex-row bg-[#fafafa] pb-5 px-3 lg:px-28 justify-between ">
        <div>
          <img
            src="/assets/website_logo_footer.png"
            className="w-30 md:w-52 h-30 md:h-44 cursor-pointer"
            alt="company logo"
          />
        </div>
        <div className="flex flex-col mt-5 items-start sm:items-center">
          <a
            href="https://www.importjdmmagic.com/find-a-vehicle"
            className="text-[#4c00b0] font-medium text-xl leading-7 underline"
          >
            Contact us
          </a>
          <a
            href="https://www.importjdmmagic.com/terms-and-conditions"
            className="text-[#4c00b0] font-medium text-xl leading-7 underline mt-3"
          >
            Terms & Conditions
          </a>
          <a
            href="https://www.importjdmmagic.com/privacy-policy"
            className="text-[#4c00b0] font-medium text-xl leading-7 underline mt-3"
          >
            Privacy Policy
          </a>
          <a
            href="https://www.importjdmmagic.com/shipping-and-returns"
            className="text-[#4c00b0] font-medium text-xl leading-7 underline mt-3"
          >
            Shipping & Returns
          </a>
        </div>
        <div className="flex flex-col items-start mt-5">
          <p className="font-bold text-2xl leading-7 text-[#4c00b0]">
            Location
          </p>
          <p className="font-medium text-base leading-7 mt-1">
            501 Church Street NE Vienna, Virginia 22180
          </p>
          <p className="font-bold text-2xl leading-7 text-[#4c00b0] mt-3">
            Phone
          </p>
          <p className="font-medium text-base leading-7 mt-1">703-249-9922</p>
          <p className="font-bold text-2xl leading-7 text-[#4c00b0] mt-3">
            Email
          </p>
          <p className="font-medium text-base leading-7 mt-1">
            sales@magicmotorsports.xyz
          </p>
        </div>
      </div>
      <div className="py-3 bg-[#4c00b0]">
        <p className="text-white">
          © Copyright 2022 All Rights Reserved Magic Motorsports
        </p>
      </div>
    </div>
  );
};

export default Footer;
