import React, { useState } from "react";
import { connect } from "react-redux";
import { resetUserData, setUserData } from "../actions/userActions";
import { resetSearchFromState, resetFilterFromState } from "../actions/searchBarActions";
import { WithUserMenu } from "../layouts/with-menu";
import { pathOr } from "ramda";
import { Form, Formik } from "formik";
import { Button, TextField } from "@mui/material";
import { updateProfile } from "../services";
import WithAuth from "../layouts/with-auth";

const ProfileSetting = (props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  return (
    <div className="flex flex-1 flex-col md:flex-[0.8] p-5 pb-16 bg-[#fafafa]">
      <p className="text-[#053361] text-2xl font-bold text-left">
        Profile Setting
      </p>
      <div className="flex flex-col w-full bg-white mt-5 p-5 border rounded-lg">
        <div className="flex justify-between">
          <p className="text-[#053361] text-base font-bold">
            Personal Information
          </p>
          {!isEditMode && (
            <p
              className="text-[#053361] text-sm underline cursor-pointer"
              onClick={() => {
                setIsEditMode(true);
              }}
            >
              Update
            </p>
          )}
        </div>
        {!isEditMode && (
          <div className="flex flex-col items-start">
            <p className="text-[#053361] text-sm font-bold mt-3">Name</p>
            <p className="text-[#2a343d] text-lg mt-1">
              {props?.user?.firstName} {props?.user?.lastName}
            </p>
            <p className="text-[#053361] text-sm font-bold mt-3">Email</p>
            <p className="text-[#2a343d] text-lg mt-1">{props?.user?.email}</p>
          </div>
        )}
        {isEditMode && (
          <div>
            <Formik
              initialValues={{
                firstName: props?.user?.firstName,
                lastName: props?.user?.lastName,
                email: props?.user?.email,
              }}
              enableReinitialize={true}
              validate={async (values) => {
                const errors = {};
                if (!values?.firstName) {
                  errors.firstName = "Please enter first name";
                }
                if (!values.lastName) {
                  errors.lastName = "Please enter last name";
                }
                if (!values.email) {
                  errors.email = "Please enter email";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }
                return errors;
              }}
              onSubmit={async (values) => {
                updateProfile({
                  firstName: values?.firstName,
                  lastName: values?.lastName,
                }).then((res) => {
                  if (res.status === 201) {
                    console.log("response", res);
                    props.setUserData(res.data);
                    setIsEditMode(false);
                  }
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit} className="flex flex-col">
                  <div className="flex flex-col sm:flex-row gap-3 mt-2">
                    <div className="flex flex-col sm:flex-[0.5] items-start">
                      <p className="text-left text-base">First Name</p>
                      <TextField
                        name="firstName"
                        onChange={handleChange}
                        value={values.firstName}
                        onBlur={handleBlur}
                        variant="standard"
                        className="w-full"
                      />
                      {errors?.firstName && touched?.firstName && (
                        <p className="text-left text-xs text-red-700">
                          {errors.firstName}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col sm:flex-[0.5] items-start">
                      <p className="text-left text-base">Last Name</p>
                      <TextField
                        name="lastName"
                        onChange={handleChange}
                        value={values.lastName}
                        onBlur={handleBlur}
                        variant="standard"
                        className="w-full"
                      />
                      {errors?.lastName && touched?.lastName && (
                        <p className="text-left text-xs text-red-700">
                          {errors.lastName}
                        </p>
                      )}
                    </div>
                  </div>
                  <p className="text-left text-base mt-2">Email</p>
                  <TextField
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    onBlur={handleBlur}
                    variant="standard"
                    disabled={true}
                  />
                  {errors?.email && touched?.email && (
                    <p className="text-left text-xs text-red-700">
                      {errors.email}
                    </p>
                  )}
                  <div className="flex flex-col sm:flex-row mt-5 gap-x-4 w-full">
                    <Button
                      variant="outlined"
                      className="w-full"
                      disabled={isSubmitting}
                      onClick={() => {
                        setIsEditMode(false);
                      }}
                    >
                      CANCEL
                    </Button>
                    <Button
                      variant="outlined"
                      type="submit"
                      className="w-full"
                      disabled={isSubmitting}
                    >
                      UPDATE
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: pathOr({}, ["user"], state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
    resetUserData: () => {
      dispatch(resetUserData());
      dispatch(resetSearchFromState());
      dispatch(resetFilterFromState());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithAuth(WithUserMenu(ProfileSetting)));
