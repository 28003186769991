import React, { useEffect } from "react";
import { useNavigate } from "react-router";

const WithAuth = (Component) => {
  function WithAuthCheck(props) {
    const navigate = useNavigate();
    const isLoggedIn = props.user.isLoggedIn;
    useEffect(() => {
      if (!isLoggedIn) {
        navigate("/signin");
      }
    }, [isLoggedIn]);

    return <Component {...props} />;
  }
  return WithAuthCheck;
};

export default WithAuth;
