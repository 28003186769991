import React, { useCallback, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const ImageGrid = ({ images, className }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };



  return (
    <div
      className={`grid grid-rows-2 grid-flow-col h-[300px] lg:h-[550px] gap-1 ${className}`} 
      style={{maxHeight:"600px"}}
    >
      <Carousel
      showArrows
      showIndicators
      renderArrowPrev={(clickHandler, hasPrev) => {
        return (
          <div
            className={`${
              hasPrev && !isViewerOpen ? "absolute" : "hidden"
            } top-0 bottom-0 left-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20`}
            onClick={clickHandler}
          >
            <ArrowBackIosNewIcon sx={{ fontSize: 40, color:"white" }}/>
          </div>
        );
      }}
      renderArrowNext={(clickHandler, hasNext) => {
        return (
          <div
            className={`${
              hasNext && !isViewerOpen ? "absolute" : "hidden"
            } top-0 bottom-0 right-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20`}
            onClick={clickHandler}
          >
            <ArrowForwardIosIcon sx={{ fontSize: 40, color:"white" }} />
          </div>
        )}
      }>
      {createDiv(images,className,openImageViewer)}
      </Carousel>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.6)",
          }}
        />
      )}
    </div>
  );
};

export default ImageGrid;

const createDiv = (images, className,openImageViewer) => {
  let divs = [];
  let i = 0;
  let startIndex = 0;
  while (i < images?.length) {
    divs.push(
      <div
      className={`grid grid-rows-2 grid-flow-col h-[300px] lg:h-[500px] overflow-x-auto gap-1 ${className}`}
    >
        {images?.slice(startIndex, startIndex + 7).map((image, index) => {
          return (
            <div 
              key={index}
              style={{cursor:"pointer"}}
              className={`${
                index === 0
                  ? // ? "row-span-2 w-[426px] lg:w-[750px]"
                    "row-span-2 "
                  : // : "w-[211px] lg:w-[375px]"
                    ""
              } rounded-sm`}
              onClick={() => openImageViewer(index)}
            >
              <img
                src={image}
                style={{ height: "100%", width: "100%"  }}
                className="rounded-sm"
                alt="car images"
                key={index}
              />
            </div>
          );
        })}
      </div>
    );
    startIndex += 7;
    i += 7;
  }
  return divs;
};