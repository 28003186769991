import axios from "axios";

const axiosInterceptors = () => {
const baseURL = "https://api.importjdmmagic.com";
  // const baseURL = process.env.BACKEND_API_URL;

  let headers = { "Access-Control-Allow-Origin": "/*" };

  const tokenExpireTime = sessionStorage.getItem("expiresIn");
  const currentTime = new Date().getTime();

  if (tokenExpireTime && currentTime > tokenExpireTime) {
    const origin = window.location.origin;
    window.location.href = origin + "/signin";
  }

  if (sessionStorage.getItem("token")) {
    headers.Authorization = "Bearer " + sessionStorage.getItem("token");
  }

  const axiosInterceptors = axios.create({
    baseURL: baseURL,
    headers,
    validateStatus: function (status) {
      return status <= 500;
    },
  });
  axiosInterceptors.interceptors.response.use(
    (response) => {
      if (response?.data?.status === 401) {
        const origin = window.location.origin;
        window.location.href = origin + "/signin";
      }
      if (response?.data?.data?.token) {
        if (sessionStorage.getItem("token")) {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("expiresIn");
          sessionStorage.setItem("token", response?.data?.data?.token);
          sessionStorage.setItem(
            "expiresIn",
            new Date().getTime() + 3600000 * 48
          );
        } else {
          sessionStorage.setItem("token", response?.data?.data?.token);
          sessionStorage.setItem(
            "expiresIn",
            new Date().getTime() + 3600000 * 48
          );
        }
      }

      return new Promise((resolve, reject) => {
        resolve(response.data);
      });
    },
    (error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );

  return axiosInterceptors;
};

export default axiosInterceptors;
